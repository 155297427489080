import { Inject, Injectable } from '@angular/core';
import { getBrowserLanguage } from '@memberspot/frontend/shared/util/translation';
import { WINDOW } from '@ng-web-apis/common';
import { TranslocoService } from '@ngneat/transloco';
import { combineLatest, of } from 'rxjs';
import { filter, first, map } from 'rxjs/operators';

import { AuthQuery } from './state/auth.query';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  private language$ = this.authQuery.selectLanguage().pipe(filter((l) => !!l));

  private initialize = false;

  constructor(
    private authQuery: AuthQuery,
    private translocoService: TranslocoService,
    @Inject(WINDOW) private window: Window,
  ) {}

  startLanguageStream() {
    if (this.initialize) {
      return;
    }

    this.initialize = true;
    this.language$.subscribe((lang) => {
      if (lang) {
        this.translocoService.setActiveLang(lang);
      }
    });
  }

  selectLanguage() {
    return combineLatest([
      this.authQuery.selectLanguage().pipe(first()),
      of(getBrowserLanguage(this.window)),
    ]).pipe(
      first(),
      map(([profileLang, browserLang]) => profileLang || browserLang),
    );
  }
}
